@import url('https://fonts.googleapis.com/css2?family=Readex+Pro:wght@160..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Readex+Pro:wght@160..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Readex+Pro:wght@160..700&display=swap');



html { 
  font-size: 1.25vw; 
  scroll-behavior: smooth;
  /* overflow:initial !important; */
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "ReadexRegular", sans-serif;
  font-style: normal;
  color: #fff;
}


body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  box-sizing: border-box;
  background-color: var(--blackSecondary);
}

::-webkit-scrollbar {
  width: 0;
  height: 0;
}






input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;

}


video {
  object-fit: cover;
}
video::-webkit-media-controls {
  display: none !important;
}
video::-webkit-media-controls-start-playback-button {
  display: none !important;
  -webkit-appearance: none;
}

h1{
  font-size: 2.5rem;
  font-weight: 500;
  line-height: 2.75rem; 
  letter-spacing: -0.045rem;
  font-family: "Readex Pro", sans-serif;

}


h2{
  text-align: center;
  font-size: 1.875rem;
  font-weight: 600;
  line-height: 2.25rem;
  font-family: "Readex Pro", sans-serif;
}

h3{
  text-align: center;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.3rem;
  font-family: "Poppins", sans-serif;
}

p{
  color: var(--whitePrimary);
  text-align: center;
  font-size: .75rem;
  font-weight: 300;
  line-height: 1.2rem; 
  font-family: "Inter", sans-serif;
}

.ant-select-item-option-content{
  font-size: 1rem;
}
.ant-form-item-row, .ant-form-item-control-input, .ant-form-item-control-input-content{
  height: 100% !important;
}

::placeholder{
  text-align: start;
  color: var(--lightGraySecondary);

}

:where(.css-dev-only-do-not-override-2i2tap).ant-picker:hover,
:where(.css-dev-only-do-not-override-2i2tap).ant-picker:focus {
  border-color: var(--lightGraySecondary);
}


span,small{
  color: var(--whitePrimary);
  line-height: 1.2rem;
  font-size: .8rem;
  font-family: "Poppins", sans-serif;
}

a, button span{
  color: var(--whitePrimary);
  font-size: .8rem;
  line-height: 1.25rem;
  font-family: "Inter", sans-serif;
}


.ant-popover .ant-popover-inner{
  background-color: var(--blackSecondary);
  padding: 7px 10px;
}

.ant-popover-arrow{
 color: var(--blackSecondary);
}

.ant-popover-inner-content{
  color: #fff !important;
  font-size: .9rem;
}

img, video{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
::placeholder{
  text-transform: capitalize;
}


:where(.css-dev-only-do-not-override-2i2tap).ant-btn-default:disabled, :where(.css-dev-only-do-not-override-2i2tap).ant-btn-default.ant-btn-disabled{
  background-color: var(--lightGrayPrimary);
}

:where(.css-dev-only-do-not-override-2i2tap).ant-btn-default:disabled span, :where(.css-dev-only-do-not-override-2i2tap).ant-btn-default.ant-btn-disabled span{
   color: #fff;
}


:where(.css-dev-only-do-not-override-2i2tap).ant-picker-focused.ant-picker {
  border-color: #eee;
  box-shadow: none;
}
.ant-dropdown-menu{
  border-radius: 0 !important;
}

:where(.css-dev-only-do-not-override-1r287do).ant-btn-default:not(
  :disabled
):not(.ant-btn-disabled):hover {
 background-color: var(--yellowPrimary);
 border-color: var(--yellowPrimary);
}

:where(.css-dev-only-do-not-override-1r287do).ant-btn-default:not(
  :disabled
):not(.ant-btn-disabled):hover span{
 color: var(--blackPrimary);
 background-color: transparent;
}


@media (max-width: 1950px)and (max-height: 1080px)  {
  html{
    font-size: 1vw;
  }
}

@media (max-width: 1600px) {
  html{
    font-size: 1.15vw;
  }
}



@media (max-width: 1400px)and (max-height: 1020px) {
  html{
    font-size: 1.3vw;
  }
}



@media (max-width: 1300px) and (min-height: 1020px) {
  html{
    font-size: 1.8vw;
  }
}

@media (max-width: 1050px) {
  html{
    font-size: 2.5vw;
  }
}


@media (max-width: 1025px) and (max-height: 770px) {
  html{
    font-size: 1.8vw;
  }
}

@media (max-width: 600px) {
html{
  font-size: 3.3vw;
}

}  